import React from "react";
import styles from "../style.module.scss";
import Text from "components/Text";
import PdcIcon from "../../../assets/images/pdc-icon.jpg";

export default function LatestCard() {
  return (
    <div className={styles["latest-card-box"]}>
      <div className={styles["latest-event-icon"]}>
        <img src={PdcIcon} alt="event-icon" />
      </div>
      <div>
        <Text className={styles["latest-event-heading"]}>
          PDC (Pacific Dental Conference)
        </Text>
      </div>

      <div className={styles["latest-event-detail"]}>
        <div className="d-flex align-items-center">
          <div className={styles["custom-dot"]}></div>
          <span className={styles["event-date"]}>March 6 – 8, 2025</span>
        </div>
        <div className="d-flex align-items-center">
          <div className={styles["custom-dot"]}></div>
          <span className={styles["booth-id"]}>Booth 851</span>
        </div>
        <div className="d-flex align-items-center">
          <div className={styles["custom-dot"]}></div>
          <span className={styles["event-location"]}>Vancouver, Canada</span>
        </div>
      </div>
      <div className={styles["event-button-box"]}>
        <a
          href="https://meetings.hubspot.com/madjid-rassamanesh/pdc2025"
          target="blank"
          className={styles["latest-custom-events-button"]}
        >
          <button className="button button-round button-shadow">
            Book an Onsite Demo
          </button>
        </a>
        <a
          href="https://na.eventscloud.com/website/79077/"
          target="blank"
          className={styles["view-event-detail"]}
        >
          View Tradeshow Details
        </a>
      </div>
    </div>
  );
}
