import PdcImage from "../../assets/images/landing-pages/Pdc-Events.png";
import PdcIcon from "../../assets/images/pdc-icon.jpg";
import JdiqIcon from "../../assets/images/landing-pages/jdiq-events-icon.png";
import OdaIcon from '../../assets/images/landing-pages/oda-event-icon.png'

const UpcomingEvent = [
  
];

const PastEvent = [
  {
    iconPath: JdiqIcon,
    eventHeading:
      "JDIQ (Journees Dentaires Intenationaes Quebec) Montreal, Canada",
    link: "https://jdiq.ca/future-convention/?lang=en",
    imagePath: PdcImage,
    date: "May 24-28, 2024",
    text: "Booth 507",
  },
  {
    iconPath: OdaIcon,
    eventHeading:
      "ODA (Ontario Dental Associates)",
    link: "https://asm.oda.ca/",
    imagePath: PdcImage,
    date: "April 18th-20th, 2024",
    text: "Booth 233",
  },
  {
    iconPath: PdcIcon,
    eventHeading:
      "PDC (Pacific Dental Conference)",
    link: "https://www.pdconf.com/ncs2024/",
    imagePath: PdcImage,
    date: "March 7th – 9th, 2024",
    text: "Booth 1212",
  },
];

export { UpcomingEvent, PastEvent };
